import { createRouter, createWebHashHistory } from 'vue-router'
const originalPush = createRouter.prototype.push
createRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'BaseLayout',
    redirect: '/product',
    component: () => import('@/layouts/BaseLayout'),
    children: [
      {
        path: '/product',
        name: '商品列表',
        component: () => import('@/views/product/ProductList'),
        meta: {requireAuth: true}
      },
      {
        path: '/product/:id/:month',
        name: '商品详情',
        component: () => import('@/views/product/ProductDetail'),
        meta: {requireAuth: true}
      },
      {
        path: '/category',
        name: '品类榜',
        component: () => import('@/views/ranking/CategoryRanking'),
        meta: {requireAuth: true}
      },
      {
        path: '/brand-rank',
        name: '品牌榜',
        component: () => import('@/views/brandRank/BrankRanking'),
        meta: {requireAuth: true}
      },
      {
        path: '/category/highGrowth',
        name: '高增长品类榜',
        component: () => import('@/views/ranking/HighGrowthCategoryRanking'),
        meta: {requireAuth: true}
      },
      {
        path: '/data',
        name: '数据罗盘',
        component: () => import('@/views/data/DashBoard'),
        meta: {requireAuth: true}
      },
      {
        path: '/login',
        name: '登录',
        component: () => import('@/views/login/UserLogin'),
        meta: {requireAuth: true}
      }
    ]
  },
  {
    path: '/pay',
    name: '支付结果页',
    component: () => import('@/views/pay/PayResult'),
    meta: {requireAuth: true}
  },
  {
    path: '/paySuccess',
    name: '支付成功页',
    component: () => import('@/views/pay/PaySuccess'),
    meta: {requireAuth: true}
  },
  {
    path: '/payFail',
    name: '支付失败页',
    component: () => import('@/views/pay/PayFail'),
    meta: {requireAuth: true}
  },
  {
    path: '/payCancel',
    name: '支付取消页',
    component: () => import('@/views/pay/PayCancel'),
    meta: {requireAuth: true}
  },
  {
    path: '/ChatTest',
    name: '对话测试',
    component: () => import('@/views/test/ChatTest'),
    meta: {requireAuth: true}
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
