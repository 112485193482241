import { createStore } from 'vuex';

const store = createStore({
  state: {
    login_user: JSON.parse(localStorage.getItem('login_user')) || { username: '' }
  },
  mutations: {
    setUser(state, newUser) {
      state.login_user = newUser;
      localStorage.setItem('login_user', JSON.stringify(newUser));
    },
    clearUser(state) {
      state.login_user = { username: '' };
      localStorage.removeItem('login_user');
    }
  },
  getters: {
    getUsername: state => state.login_user.username
  },
  actions: {
    login({ commit }, user) {
      commit('setUser', user);
    },
    logout({ commit }) {
      commit('clearUser');
    },
    initUser({ commit }) {
      const user = JSON.parse(localStorage.getItem('login_user'));
      if (user) {
        commit('setUser', user);
      }
    }
  },
  modules: {}
});

export default store;
